import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";
import Link from 'gatsby-link';
import Newarrow from '../../assets/image/jpeg/enter.svg';



const Bookbutton = styled.button`
position: relative;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
height: 100%;
padding: 0.8em 2.4em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
font-family: azo-sans-web, sans-serif;
color: #fff;
font-size: 0.7em;
letter-spacing: 2px;
text-decoration: none;
text-transform: uppercase;
cursor: pointer;
border:none;
background-color: rgb(171,142,102);
color: #fff;
opacity: 0.8;
transition: 0.3s;

&:hover{
  opacity: 1
}
`;





const Arrow = styled.div`


width: 1.8em;
margin-left: 0.8em;
opacity: 0.6;

`;



const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 1em;
background-color: rgb(171, 142, 102);

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;









const SecondText = styled.h3`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 2.2em;
      font-smooth: always;
      margin-bottom: 0.4em;
     
      color: #fff;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 24px;
          text-align:center;
      }

`;

const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`

  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  max-width: 100%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    
  }
`;

const MetwoPage = () => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section py={4} bg="#1a1a1a">
      <Container className="pt-lg-5 pb-lg-5">

          <Row>
              <Col>
              <Box className=" pt-lg-0">
      <SecondText>
      Mesotherapy & Platelet-rich Plasma (PRP)
      </SecondText>
      <Text color="#fff">
 
      For the most effective results, a powerful combination of Platelet Rich Plasma (PRP) and Mesotherapy has been successful in hair strengthening and growth as well as stimulating hair regrowth in men and women of all ages. <br /> <br />
PRP  for hair promotes healthy hair growth using your body’s own natural growth elements, making your thinning hair grow back thicker, fuller and stronger. <br /> <br />
Mesotherapy for hair uses specialised cocktails that get inserted into the scalp. The elements contained in the microinjections contain ample nutrients and growth factors, needed for the strengthening and survival of hair follicles. <br /> <br />
By combining mesotherapy and PRP, you can gain the benefits of both, allowing Dermamina London to fully utilise the healing capabilities of the PRP and the unique fusion of cocktail of mesotherapy that is needed to stimulate hair follicles and promote a healthy scalp.<br /> <br />
 


 
      </Text>

      <br />
            
            <Link to="/hair-restoration-london"><Bookbutton>Learn More
                </Bookbutton> </Link>
                  <br />
</ Box>
              </Col>
          </Row>
       
      </Container>
    </Section>
  </>
);

export default MetwoPage;
