import React, { useState } from "react"
import PageWrapper from "../components/PageWrapper";
import Rte from "../sections/landing/popular.js";

import ACt from '../sections/ameso/mecontent.js';
import Head from "../components/head";
import { JsonLd } from '../components/JsonLd';
import Saveface from "../sections/landing/saveface.js";
import Recovery from "../sections/ameso/meglance.js";
import Pro from '../sections/ameso/mesteps.js';
import Others from '../sections/ameso/mectwo.js';
import Acco from "../sections/ameso/mefaq.js";
import Clinic from '../sections/nose/nosefind.js'; 
import Testimonial from '../sections/index/indexreviews.js';   
import HeroDesktop from "../sections/ameso/mehero.js";
import HeroBanner from "../sections/ameso/mebanner.js";
import Award from "../sections/index/award.js";
import PopupForm from "../components/PopupForm/PopupForm"


const MesothairPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
  <>
 <Head title="Mesotherapy For Hair Growth in London" image="/images/dermamina-home.jpg"  description="Balance or straighten your nose in a same-day appointment. A non-surgical rhinoplasty using dermal fillers is a safe, and non-invasive treatment. " keywords="Nose jobs, Liquid nose job, Liquid rhinoplasty, non Surgical nose job London, Nose filler, Non surgical rhinoplasty, Non surgical nose job, Pain free nose job, non surgical rhinoplasty London, non surgical nose job harley street"/>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
  '@type': 'ContactPoint',
  telephone: '020 7099 6650',
  contactType: 'Customer service',
},
address:{
  '@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
  '@type': 'ImageObject',
  url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
  {'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Non-Surgical Rhinoplasty',
item:'https://www.dermamina.com/non-surgical-nose-job',
},

{'@type': 'ListItem', 
position:3,
name:'Tear Trough Fillers Under Eye',
item:'https://www.dermamina.com/tear-trough-under-eye',
},

]
}}
</JsonLd>
      <PageWrapper headerDark footerDark>
        <HeroBanner />
      <HeroDesktop />
      <Recovery />
      <Award />
      <Testimonial />
        <PopupForm
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      <ACt
        setIsModalOpen={setIsModalOpen}
      />
    
      <Pro />
      <Others />
  <Acco
    setIsModalOpen={setIsModalOpen}
  />
  <Clinic /> 
   <Rte />

   <Saveface />
 
     </PageWrapper>
    
  </>
)}
export default MesothairPage
